// Libraries
import {useMountEffect, useState} from '@supermove/hooks';
import {CodePush} from '@supermove/sdk';

const useCodePushChecker = ({shouldCheck}) => {
  const [checking, setChecking] = useState(shouldCheck);
  const [update, setUpdate] = useState(null);

  const checkForUpdate = async () => {
    console.log('[CodePush] Checking for update...');

    try {
      const update = await CodePush.checkForUpdate();
      if (update) {
        console.log('[CodePush] Received update:', update);
        setUpdate(update);
      } else {
        console.log('[CodePush] No update, app is up-to-date.');
      }
    } catch (error) {
      console.log('[CodePush] Error:', error);
    } finally {
      setChecking(false);
    }
  };

  // Run the check once on mount.
  useMountEffect(() => {
    if (shouldCheck) {
      checkForUpdate();
    }
  });

  return {
    checking,
    update,
  };
};

export default useCodePushChecker;
